import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.4.1_next@14.2.15_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/@vercel/analytics/dist/next/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/.pnpm/@vercel+speed-insights@1.1.0_next@14.2.15_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/lib/fonts.ts\",\"import\":\"Poppins\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\",\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\"],\"display\":\"swap\"}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/lib/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../assets/fonts/GeistVF.woff\",\"variable\":\"--font-sans\",\"weight\":\"100 900\"}],\"variableName\":\"geistSans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/lib/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../assets/fonts/GeistMonoVF.woff\",\"variable\":\"--font-mono\",\"weight\":\"100 900\"}],\"variableName\":\"geistMono\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Sheet","SheetTrigger","SheetContent","SheetHeader","SheetTitle","SheetClose"] */ "/vercel/path0/src/components/ui/sheet.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/src/components/ui/sonner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WhatsAppFakeChat"] */ "/vercel/path0/src/components/whattaspp/whattsApp.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/globals.css");
